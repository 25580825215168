<template>
  <div>
    <q-table
      class="my-sticky-virtscroll-table"
      :style="`height: ${height}`"
      virtual-scroll
      :pagination.sync="pagination"
      :rows-per-page-options="[0]"
      :virtual-scroll-sticky-size-start="48"
      row-key="index"
      :title="title"
      :data="data"
      :columns="columns"
      :visible-columns="visibleColumns"
      @row-dblclick="rowDblclick"
      dense
      hide-bottom
    >
      <template v-if="enableSearch" v-slot:top-left>
        <q-input
          borderless
          dense
          debounce="300"
          color="primary"
          v-model="filter"
          label="Filtro"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>

      <template v-slot:top-right>
        <div v-if="actions">
          <q-btn
            v-for="(action, index) in actions"
            :key="index"
            :color="action.color"
            :class="action.class"
            :icon-right="action.icon"
            :label="action.label"
            no-caps
            @click="action.handle"
          />
        </div>

        <q-select
          v-if="showVisibleColumns"
          v-model="visibleColumns"
          multiple
          outlined
          dense
          options-dense
          :display-value="$q.lang.table.columns"
          emit-value
          map-options
          :options="columns"
          option-value="name"
          options-cover
          style="min-width: 150px"
        />
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            v-for="(action, index) in props.col.actions"
            :key="index"
            round
            dense
            size="sm"
            flat
            :color="action.color"
            :icon="action.icon"
            @click="action.handler(props.row)"
          >
            <q-tooltip
              v-if="action.tooltip"
              :content-class="`bg-${action.color}`"
              >{{ action.tooltip }}</q-tooltip
            >
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false
    },
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    actions: {
      type: Array,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: "50vh"
    },
    showVisibleColumns: {
      type: Boolean,
      required: false,
      default: false
    },
    enableSearch: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      filter: null,
      pagination: {
        rowsPerPage: 0
      },
      visibleColumns: []
    };
  },
  created() {
    this.visibleColumns = this.columns.map(column => column.name);
  },
  methods: {
    rowDblclick(evt, row, index) {
      this.$emit("row-dblclick", evt, row, index);
    }
  },
  watch: {
    filter(newValue) {
      this.$emit("onFilter", newValue);
    }
  }
};
</script>

<style lang="sass">
.my-sticky-virtscroll-table

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th
    background-color: #d5d5d9

  thead tr th
    position: sticky
    z-index: 1
  thead tr:last-child th
    top: 48px
  thead tr:first-child th
    top: 0
</style>
